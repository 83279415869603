
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
  reactive,
  nextTick,
} from "vue";
import Header from "@/components/header/index.vue";
import Tail from "@/components/tail/index.vue";
const ivp4 = require("@/assets/imgs/ivp4.png");
const ivp5 = require("@/assets/imgs/ivp5.png");
const ivp6 = require("@/assets/imgs/ivp6.png");
const ivp7 = require("@/assets/imgs/ivp7.png");
const ivp8 = require("@/assets/imgs/ivp8.png");
const ivp9 = require("@/assets/imgs/ivp9.png");
const icon1 = require("@/assets/imgs/finextra.png");
const icon2 = require("@/assets/imgs/cison.png");
const icon3 = require("@/assets/imgs/atmicon.png");
const icon4 = require("@/assets/imgs/busness.png");

export default defineComponent({
  name: "Home",
  components: { Header, Tail },
  setup() {
    const bkcolor = ref("white");
    const data = reactive({
      app: [true, false, false, false, false, false, false],
      items: [
        {
          img: ivp4,
          title: "BuyUcoin pilots Roxe payment node in India",
          date: "April 07, 2021",
          icon: icon1,
          link: "https://www.finextra.com/pressarticle/86974/buyucoin-pilots-roxe-payment-node-in-india",
        },
        {
          img: ivp5,
          title: "Roxe advances on India with Ipay partnership",
          date: "May 27, 2021",
          icon: icon1,
          link: "https://www.finextra.com/pressarticle/86974/buyucoin-pilots-roxe-payment-node-in-india",
        },
        {
          img: ivp6,
          title: "Roxe collaborates with Treviso to launch remittance program",
          date: "August 19, 2021",
          icon: icon1,
          link: "https://www.finextra.com/pressarticle/88970/roxe-collaborates-with-treviso-to-launch-remittance-program",
        },
        {
          img: ivp7,
          title:
            "Roxe Partners with Nium to Power Seamless Real-Time Cross-Border Remittances and Payout Services in Key Global Markets",
          date: "Nov 18, 2021",
          icon: icon2,
          link: "https://www.prnewswire.com/news-releases/roxe-partners-with-nium-to-power-seamless-real-time-cross-border-remittances-and-payout-services-in-key-global-markets-301428267.html",
        },
        {
          img: ivp8,
          title: "Axletree partners with Roxe for cross-border blockchain payments",
          date: "Dec 20, 2021",
          icon: icon3,
          link: "https://www.pymnts.com/blockchain/2021/using-blockchain-to-improve-cross-border-remittances/",
        },
        {
          img: ivp9,
          title: "Apifiny Partners with ECS Fin to Launch Pilot Program of Roxe Network",
          date: "Sep 17, 2020",
          link: "https://www.businesswire.com/news/home/20200917005216/en/Apifiny-Partners-with-ECS-Fin-to-Launch-Pilot-Program-of-Roxe-Network",
          icon: icon4,
        },
      ],
    });
    const winScroll = () => {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      console.log(`scrollTop:${scrollTop}`);
      data.app = [false, false, false, false, false, false, false];
      if (scrollTop < 1300) {
        data.app[0] = true;
      } else if (scrollTop < 1900) {
        data.app[1] = true;
      } else if (scrollTop < 2500) {
        data.app[2] = true;
      } else if (scrollTop < 3200) {
        data.app[3] = true;
      } else if (scrollTop < 3800) {
        data.app[4] = true;
      } else if (scrollTop < 4500) {
        data.app[5] = true;
      } else {
        data.app[6] = true;
      }
    };
    onMounted(() => {
      window.addEventListener("scroll", winScroll);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("scroll", winScroll);
    });
    const showapp = ref(true);
    const showcbdc = ref(false);
    const showmin = ref(false);
    const showimg = computed(() => {
      return showapp.value || showcbdc.value || showmin.value;
    });
    const gotolink = (url: string) => {
      window.open(url);
    };
    return { gotolink, data, showimg, bkcolor, showapp, showcbdc, showmin };
  },
});
